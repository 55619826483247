<template>
  <v-container
      fluid
      tag="section"
  >
    <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
      <v-col cols="12">
        <v-row v-if="user">
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="12" class="mb-10">
                <v-row class="ma-0">
                  <v-col class="mr-5" cols="auto" align="center" align-self="center">
                    <input id="avatar_input" type="file" hidden @change="uploadAvatar" accept="image/png, image/jpeg, image/jpg">
                    <v-badge
                        bordered
                        color="primary"
                        icon="mdi-cloud-upload-outline"
                        overlap
                        bottom
                        avatar
                    >
                      <v-btn
                          fab
                          x-large
                          @click="chooseFile"
                          :loading="uploading"
                          :disabled="uploading"
                          elevation="0"
                          color="transparent"
                      >
                        <v-avatar size="100" class="elevation-5">
                          <v-img :src="$store.getters.user.user.avatar ? $store.getters.user.user.avatar : '/assets/img/avatar.png'">
                            <template v-slot:placeholder>
                              <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                              >
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>
                    </v-badge>
                  </v-col>
                  <v-col align-self="center" justify="center">
                    <div class="text-h6 main-font-bold">{{ user.firstname }} {{ user.name }}</div>
                    <div class="subtitle-1 font-weight-light main-grey mt-0 pt-0 pb-10"><span class="pr-5">{{ $t('personal_information') }}</span>
                      <HelpVideo video="profil" title="Mein Profil"></HelpVideo>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6" md="3" class="py-2 ">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span class="text-break">{{ $t('first_name') }}</span>
                    <v-btn v-if="active !== 'firstname'" class="ml-2" icon @click="handleActivateField('firstname')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'firstname'" color="error" class="ml-2" icon @click="closeRestoreOriginalValue">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <transition name="fade" mode="out-in">
                    <div
                        class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break"
                        v-if="active !== 'firstname'"
                    >
                      {{ user.firstname ? user.firstname : '–' }}
                    </div>
                    <v-text-field
                        outlined
                        hide-details
                        class="mt-2"
                        v-model="user.firstname"
                        :loading="activeLoading === 'firstname'"
                        :disabled="activeLoading === 'firstname'"
                        @keyup.enter="updateProfile('firstname')"
                        v-else
                    >
                      <template v-slot:append>
                        <v-icon
                            @click="updateProfile('firstname')"
                            color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </template>
                    </v-text-field>
                  </transition>
                </div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span class="text-break">{{ $t('last_name') }}</span>
                    <v-btn v-if="active !== 'name'" class="ml-2" icon @click="handleActivateField('name')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'name'" color="error" class="ml-2" icon @click="closeRestoreOriginalValue">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <transition name="slide" mode="out-in">
                    <div
                        class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break"
                        v-if="active !== 'name'"
                    >
                      {{ user.name ? user.name : '–' }}
                    </div>
                    <v-text-field
                        outlined
                        hide-details
                        class="mt-2"
                        v-model="user.name"
                        :loading="activeLoading === 'name'"
                        :disabled="activeLoading === 'name'"
                        @keyup.enter="updateProfile('name')"
                        v-else
                    >
                      <template v-slot:append>
                        <v-icon
                            @click="updateProfile('name')"
                            color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </template>
                    </v-text-field>
                  </transition>
                </div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span class="text-break">{{ $t('address') }}</span>
                    <v-btn v-if="active !== 'address'" class="ml-2" icon @click="handleActivateField('address')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'address'" color="error" class="ml-2" icon @click="closeRestoreOriginalValue">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <transition name="slide" mode="out-in">
                    <div
                        class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break"
                        v-if="active !== 'address'"
                    >
                      {{ user.address ? prepareAddress(user.address) : '–' }}
                    </div>
                    <v-textarea
                        outlined
                        hide-details
                        class="mt-2"
                        rows="3"
                        v-model="user.address"
                        :loading="activeLoading === 'address'"
                        :disabled="activeLoading === 'address'"
                        v-else
                    >
                      <template v-slot:append>
                        <v-icon
                            @click="updateProfile('address')"
                            color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </template>
                    </v-textarea>
                  </transition>
                </div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span class="text-break">{{ $t('birthplace') }}</span>
                    <v-btn v-if="active !== 'birthplace'" class="ml-2" icon @click="handleActivateField('birthplace')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'birthplace'" color="error" class="ml-2" icon @click="closeRestoreOriginalValue">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <transition name="slide" mode="out-in">
                    <div
                        class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break"
                        v-if="active !== 'birthplace'"
                    >
                      {{ user.birthplace ? user.birthplace : '–' }}
                    </div>
                    <v-text-field
                        outlined
                        hide-details
                        class="mt-2"
                        v-model="user.birthplace"
                        :loading="activeLoading === 'birthplace'"
                        :disabled="activeLoading === 'birthplace'"
                        @keyup.enter="updateProfile('birthplace')"
                        v-else
                    >
                      <template v-slot:append>
                        <v-icon
                            @click="updateProfile('birthplace')"
                            color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </template>
                    </v-text-field>
                  </transition>
                </div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span class="text-break">{{ $t('birthdate') }}</span>
                    <v-btn v-if="active !== 'birthdate'" class="ml-2" icon @click="handleActivateField('birthdate')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'birthdate'" color="error" class="ml-2" icon @click="closeRestoreOriginalValue">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <transition name="slide" mode="out-in">
                    <div
                        class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break"
                        v-if="active !== 'birthdate'"
                    >
                      {{ user.birthdate ? formattedBirthdate : '–' }}
                    </div>
                    <v-menu
                        v-model="birthdateDatePickerMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        v-else
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="formattedBirthdate"
                            class="mt-2"
                            :loading="activeLoading === 'birthdate'"
                            :disabled="activeLoading === 'birthdate'"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          locale="de"
                          color="primary"
                          v-model="user.birthdate"
                          :max="maxDate"
                          min="1900-01-01"
                          @input="onBirthdateDatePickerInput"
                      ></v-date-picker>
                    </v-menu>
                  </transition>
                </div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1">{{ $t('age') }}</div>
                  <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break">{{ calculatedAge ? calculatedAge : '–' }}</div>
                </div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span class="text-break">{{ $t('phone') }}</span>
                    <v-btn v-if="active !== 'phone'" class="ml-2" icon @click="handleActivateField('phone')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'phone'" color="error" class="ml-2" icon @click="closeRestoreOriginalValue">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <transition name="slide" mode="out-in">
                    <div
                        class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break"
                        v-if="active !== 'phone'"
                    >
                      {{ user.phone ? user.phone : '–' }}
                    </div>
                    <v-text-field
                        outlined
                        hide-details
                        class="mt-2"
                        v-model="user.phone"
                        :loading="activeLoading === 'phone'"
                        :disabled="activeLoading === 'phone'"
                        @keyup.enter="updateProfile('phone')"
                        v-else
                    >
                      <template v-slot:append>
                        <v-icon
                            @click="updateProfile('phone')"
                            color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </template>
                    </v-text-field>
                  </transition>
                </div>
              </v-col>
              <v-col cols="6" md="3" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span class="text-break">{{ $t('indent_nr') }}</span>
                    <v-btn v-if="active !== 'indent_nr'" class="ml-2" icon @click="handleActivateField('indent_nr')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'indent_nr'" color="error" class="ml-2" icon @click="closeRestoreOriginalValue">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <transition name="slide" mode="out-in">
                    <div
                        class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4 text-break"
                        v-if="active !== 'indent_nr'"
                    >
                      {{ user.indent_nr ? user.indent_nr : '–' }}
                    </div>
                    <v-text-field
                        outlined
                        hide-details
                        class="mt-2"
                        v-model="user.indent_nr"
                        :loading="activeLoading === 'indent_nr'"
                        :disabled="activeLoading === 'indent_nr'"
                        @keyup.enter="updateProfile('indent_nr')"
                        v-else
                    >
                      <template v-slot:append>
                        <v-icon
                            @click="updateProfile('indent_nr')"
                            color="primary"
                        >
                          mdi-check
                        </v-icon>
                      </template>
                    </v-text-field>
                  </transition>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelpVideo from "@/widgets/HelpVideo.vue";

export default {
  data() {
    return {
      loading: true,
      user: null,
      originalValue: null,
      active: false,
      activeLoading: false,
      uploading: false,
      birthdateDatePickerMenu: false,
      maxDate: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)
          .toISOString().substring(0, 10), // Max date: today
    }
  },
  mounted() {
    this.getMe()
  },
  components: {
    HelpVideo,
  },
  computed: {
    formattedBirthdate() {
      if (!this.user.birthdate) {
        return '';
      }

      return this.$helpers.formatDate(this.user.birthdate, 'DD.MM.YYYY');
    },
    calculatedAge() {
      const today = new Date(this.maxDate);
      const birthdate = this.user.birthdate ? new Date(this.user.birthdate) : null;

      if (!birthdate) {
        return null;
      }

      const differenceInMs = today - birthdate;

      return Math.floor(differenceInMs / (1000 * 60 * 60 * 24 * 365.25));
    }
  },
  methods: {
    getMe() {
      this.loading = true

      this.$store.dispatch('getMe')
          .then(resp => {
            this.user = resp
            this.loading = false
            this.active = false
            this.activeLoading = false
          })
    },
    updateProfile(tag) {
      this.activeLoading = tag

      this.$store.dispatch('updateProfile', this.user)
          .then(() => {
            this.getMe()

            this.$helpers.showMessage('success', this.$t('profile_updated'))
          })
          .catch(error => {
            this.$helpers.showError(error)
            this.activeLoading = false
          })
    },
    chooseFile() {
      document.getElementById("avatar_input").click()
    },
    uploadAvatar(event) {
      this.uploading = true

      let data = new FormData()
      data.append('avatar', event.target.files[0])

      this.$store.dispatch('uploadAvatar', data)
          .then(() => {
            this.$store.dispatch('getMe')
                .then(() => {
                  this.uploading = false
                })
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.uploading = false
          })
    },
    prepareAddress(address) {
      return address.replace(/(\r\n|\r|\n)/g, '<br>')
    },
    handleActivateField(field) {
      if (this.activeLoading) {
        return;
      }

      this.active = field;
      this.originalValue = this.user[field];
    },
    closeRestoreOriginalValue() {
      if (this.active) {
        this.user[this.active] = this.originalValue;
        this.active = null;
      }
    },
    onBirthdateDatePickerInput(value) {
      this.birthdateDatePickerMenu = false;
      this.user.birthdate = value;
      this.updateProfile('birthdate')
    },
  }
};
</script>
