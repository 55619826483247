<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">
    <!-- Head Buttons -->
    <v-row>
      <v-col cols="9" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">
          {{ $t('ticket') }} #{{ ticket ? ticket.tracking_code : "---" }}
        </p>
      </v-col>
      <v-col cols="3" align="right" justify="end" class="pt-8 pl-3 pr-3 pb-8">
        <router-link :to="{ name: 'support' }">
          <v-btn color="primary" fab>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3">
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t('created_at') }}
        </div>
        <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4">
          {{ ticket ? $helpers.formatDate(ticket.created_at, 'DD.MM.YYYY') : "---" }}
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t('last_update') }}
        </div>
        <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4">
          {{ ticket ? $helpers.formatDate(ticket.updated_at, 'DD.MM.YYYY') : "---" }}
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t('department') }}
        </div>
        <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4">
          {{ ticket ? ticket.category : "---" }}
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div class="text-subtitle-1 font-weight-bold">
          {{ $t('status') }}
        </div>
        <div class="subtitle-2 font-weight-light main-grey mt-0 pt-0 pb-4">
          {{
            ticket
                ? $t(ticket.status)
                : "---"
          }}
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-timeline v-if="ticket">
          <v-timeline-item
              v-for="(comment, n) in ticket.comments"
              :key="n"
              large
          >
            <template v-slot:icon>
              <v-avatar :color="comment.is_admin ? 'primary' : 'white'">
                <img
                    v-if="!comment.is_admin"
                    :src="
						$store.getters.user.user.avatar
						? $store.getters.user.user.avatar
						: '/assets/img/avatar.png'
					"
                />
                <span class='white--text font-weight-bold' v-else>G</span>
              </v-avatar>
            </template>

            <template v-slot:opposite>
              <p class="pb-0 mb-0">{{ $store.getters.user.user.name }}</p>
              <span class="text--disabled">{{ $helpers.formatDate(comment.created_at) }}</span>
            </template>

            <v-card class="elevation-2">
              <v-card-text>
                <p v-html="comment.content.replace(/\n/g, '<br/>')"></p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>

    <!-- New Comment -->
    <v-row v-if="ticket ? ticket.status != 'closed' : false">
      <transition name="slide" mode="out-in">
        <v-col v-if="newComment" cols="12">
          <v-textarea
              v-model="newCommentContent"
              color="primary darken-1"
              :label="$t('new_message')"
              outlined
              clearable
              :disabled="sendCommentLoading"
              :loading="sendCommentLoading"
              :error="error"
              append-outer-icon="mdi-send"
              @click:append-outer="sendNewComment"
          >
          </v-textarea>
        </v-col>
      </transition>
      <v-col cols="12" align="center" justify="center">
        <v-btn color="white" fab @click="newComment = !newComment">
          <span class="primary--text">
            <v-icon v-if="newComment">mdi-minus</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    ticket: null,
    comments: null,
    loading: true,
    newComment: false,
    newCommentContent: null,
    error: false,
    sendCommentLoading: false,
  }),
  created() {
    this.getTicket(this.$route.params.id);
  },
  methods: {
    getTicket(id) {
      this.loading = true;
      this.$store
          .dispatch("getTicket", id)
          .then((resp) => {
            this.ticket = resp;
            this.loading = false;
          })
          .catch((err) => {
          });
    },
    sendNewComment() {
      if (!this.newCommentContent) {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
        return;
      }
      this.sendCommentLoading = true;
      this.$store
          .dispatch("sendTicketComment", {
            ticket_id: this.ticket.id,
            content: this.newCommentContent
          })
          .then((resp) => {
            this.$store
                .dispatch("getTicket", this.$route.params.id)
                .then((resp) => {
                  this.ticket = resp;
                  this.newComment = false;
                  this.sendCommentLoading = false;
                  this.newCommentContent = null;
                })
                .catch((err) => {
                });
          })
          .catch((err) => {
            this.sendCommentLoading = false;
          });
    },
  },
};
</script>
