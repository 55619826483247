<template>
  <section class="d-flex flex-column">
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>
    <v-container class="max-width mb-16">
      <v-row class="mt-10">
        <v-col cols="12">
          <h1>{{ this.$t('imprint') }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="main-font">
          <div v-html="this.$store.getters.settings.impressum || ''"></div>
        </v-col>
      </v-row>
    </v-container>
    <Footer
        :showLogin="showLogin"
        :showRegister="showRegister"
        @close-login-modal="showLogin = false"
        @close-register-modal="showRegister = false"
    />
  </section>
</template>

<script>

import Header from "./Header.vue";
import Footer from "../../components/public/Footer.vue";
import TextWithImage from "../../widgets/TextWithImage";

export default {
  components: {
    TextWithImage,
    Header,
    Footer,
  },
  data() {
    return {
      showLogin: false,
      showRegister: false
    }
  },
}
</script>
