<template>
  <div class="d-inline">
    <v-hover
        v-slot:default="{ hover }"
        open-delay="200"
    >
      <v-btn
          class="btn-help-video"
          @click="dialog=true"
          elevation="5"
          small
      >
        <v-icon small color="white">mdi-help-circle</v-icon>
        <v-expand-x-transition>
          <span v-if="hover" class="ml-2">Hilfe-Video</span>
        </v-expand-x-transition>
      </v-btn>
    </v-hover>

    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          v-if="dialog"
          @input="$emit('input', $event)"
          max-width="600px"
      >
        <v-card>
          <v-card-title class="text-h6 white--text bg-primary">
            <v-col cols="10" style="padding: 0">
              <span style="color:#e5e0e0">Hilfe-Video:</span> {{ title }}
            </v-col>
            <v-col cols="2" class="text-right" style="padding: 0">
              <v-btn
                  icon
                  dark
                  @click="dialog = false"
                  class="float-end"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>

          <VideoPlayer :src="videoPath + video + '.mp4'" />
        </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>

<script>

  import VideoPlayer from "../widgets/VideoPlayer.vue";

  export default {
    props: ['video', 'title'],
    components: {
      VideoPlayer,
    },
    data(){
      return {
        dialog: false,
        videoPath: '/assets/video/help/'
      }
    },
  };
</script>
