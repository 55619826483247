<template>
  <section class="d-flex flex-column">
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>
    <v-container class="mb-15 pt-3 pt-sm-15 pb-10">
      <v-row class="mb-5">
        <v-col
            cols="12"
            md="6"
            class="pr-md-15 order-2 order-md-1"
        >
          <h1 class="mb-2 text-center text-md-left main-color">{{ $t('texts.competitions.1.title') }}</h1>
          <h3 class="mb-5 text-center text-md-left main-color">{{ $t('texts.competitions.1.subtitle') }}</h3>
          <p class="text-subtitle-1 text-center text-md-left">{{ $t('texts.competitions.1.description') }}</p>
          <TopicList :topics="topics" icon="mdi-chevron-right" mobile-icon="mdi-chevron-down" class="mb-9"></TopicList>
          <div v-if="!participated">
            <p class="text-caption text-center text-md-left">{{ $t('texts.competitions.legal') }}</p>
            <v-btn
                elevation="5"
                color="primary"
                large
                block
                class="text-uppercase"
                :loading="participateLoading"
                :disabled="participateLoading"
                @click="handleCompeteClick"
            >
              <span>{{ this.$store.getters.isLoggedIn ? $t('texts.competitions.participate') : $t('texts.competitions.register_and_participate') }}</span>
            </v-btn>
          </div>
          <v-alert
              v-else
              class="mb-0"
              type="success"
              border="left"
              colored-border
              icon="mdi-trophy-outline"
          >
            <h3 class="mb-5 text-center text-md-left main-color">{{ $t('texts.competitions.participated_title') }}</h3>
            <span class="text-center text-sm-left">{{ $t('texts.competitions.participated_description') }}</span>
          </v-alert>
        </v-col>
        <v-col
            cols="12"
            md="6"
            class="order-1 order-md-2"
        >
          <v-img
              src="/assets/img/competitions/fuechse-duesburg-2-vip-tickets-pressekonferenz-meet-greet.png"
              class="elevation-5 rounded primary"
              :alt="$t('texts.competitions.1.title')"></v-img>
        </v-col>
      </v-row>
      <v-row v-if="!participated">
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ $t('texts.competitions.legal_title') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <span v-html="this.$store.getters.settings.participation_conditions || ''"></span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <Footer
        :showLogin="showLogin"
        :showRegister="showRegister"
        :competition="competition"
        @close-login-modal="showLogin = false"
        @close-register-modal="handleCloseRegisterModal"
        @register-success="handleRegisterSuccess"
    />
  </section>
</template>
<script>
import Header from "@/components/public/Header.vue";
import Footer from "@/components/public/Footer.vue";
import TopicList from "@/widgets/TopicList.vue";

export default {
  components: {
    TopicList,
    Header,
    Footer,
  },
  data() {
    return {
      topics: [
        this.$t('texts.competitions.1.rules.rule1'),
        this.$t('texts.competitions.1.rules.rule2'),
      ],
      showLogin: false,
      showRegister: false,
      competition: null,
      participateLoading: false,
      participated: false,
    }
  },
  methods: {
    handleCompeteClick() {
      this.competition = 'fuechse2viptickets1';

      if (this.$store.getters.isLoggedIn) {
        this.participateLoading = true;
        this.$store.dispatch('addCompetitionParticipant', {
          competition_id: this.competition
        })
            .then(() => {
              this.participated = true;
            })
            .catch(err => {
              this.$helpers.showError(err)
            })
            .finally(() => {
              this.participateLoading = false;
            });
      } else {
        this.showRegister = true;
      }
    },
    handleCloseRegisterModal() {
      this.competition = null;
      this.showRegister = false;
    },
    handleRegisterSuccess() {
      this.participated = true;
    }
  }
}
</script>
