<template>
  <v-container
      fluid
      tag="section"
  >
    <v-row justify="center" class="pl-8 pr-8 pb-8 pt-3">
      <v-col cols="12">
        <v-row v-if="user">
          <v-col cols="12">
            <div class="text-h5 main-font-bold">{{ $t('account_info') }}</div>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="py-2">
                <div class="elevation-5 rounded pa-4 fill-height">
                  <div class="text-subtitle-1 d-flex align-center justify-space-between">
                    <span>{{ $t('password') }}</span>
                    <v-btn v-if="active !== 'password'" class="ml-2" icon @click="handleActivateField('password')">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="active === 'password'" color="error" class="ml-2" icon @click="closeChangePassword">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                  <transition name="slide" mode="out-in">
                    <v-row v-if="active === 'password'" class="mt-0" :key="1">
                      <v-col cols="12">
                        <v-text-field
                            v-model="changePasswordForm.password"
                            :type="showPassword ? 'text' : 'password'"
                            :label="$t('current_password')"
                            :loading="activeLoading === 'password'"
                            :disabled="activeLoading === 'password'"
                            outlined
                            hide-details
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="togglePasswordVisibility"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            v-model="changePasswordForm.new_password"
                            :label="$t('new_password')"
                            :type="showPassword ? 'text' : 'password'"
                            :loading="activeLoading === 'password'"
                            :disabled="activeLoading === 'password'"
                            outlined
                            hide-details
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="togglePasswordVisibility"
                            @input="validatePassword"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                            v-model="changePasswordForm.new_password_confirmation"
                            :label="$t('confirm_new_password')"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="togglePasswordVisibility"
                            :loading="activeLoading === 'password'"
                            :disabled="activeLoading === 'password'"
                            outlined
                            hide-details
                            @keyup.enter="changePassword"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" :align="changePasswordFormValid ? 'right' : ''">
                        <div v-if="changePasswordForm.new_password && !this.allPasswordRulesValid" class="password-rules">
                          <v-col v-for="rule in passwordRules" :key="rule.text" cols="12" class="pa-0">
                            <v-icon :color="rule.isValid ? 'success' : 'error'" size="20">
                              {{ rule.isValid ? 'mdi-check' : 'mdi-close' }}
                            </v-icon>
                            <span :class="rule.isValid ? 'text-success' : 'text-error'">
                            {{ rule.text }}
                          </span>
                          </v-col>
                        </div>
                        <v-btn
                            v-if="changePasswordFormValid"
                            :loading="activeLoading === 'password'"
                            :disabled="activeLoading === 'password'"
                            color="primary"
                            :block="$vuetify.breakpoint.smAndDown"
                            @click="changePassword"
                        >
                          {{ $t('update') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </transition>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-5" align="center">
          <v-col cols="12">
            <div class="text-h6 main-font-bold">
              <v-icon class="mr-4">mdi-qrcode-scan</v-icon>
              Zwei-Faktor-Authentifizierung
            </div>
          </v-col>
          <v-col cols='12' md="3">
            <v-row>
              <v-col cols="12" v-if="$store.getters.user.user.otp_status === 'disabled'">
                <v-card align="center">
                  <v-card-text>
                    <v-row
                        v-if="!secret.qrcode"
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                    </v-row>
                    <div v-else v-html="secret.qrcode"></div>
                  </v-card-text>
                  <v-card-text>
                    <div>Secret Code:</div>
                    <div class="text-h5">{{ secret.secret }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    class="pa-0"
                    :placeholder="$t('otp_code')"
                    :hint="$store.getters.user.user.otp_status === 'enabled' ? 'Sicherheitscode eingeben um 2FA zu deaktivieren' : 'Sicherheitscode eingeben um 2FA zu aktivieren'"
                    v-model="otp"
                    :loading="loadingOtp"
                    :disabled="loadingOtp"
                    autocomplete="one-time-code"
                    persistent-hint
                    outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                    :loading="loadingOtp"
                    :disabled="loadingOtp || !otp"
                    color="primary"
                    block
                    v-if="$store.getters.user.user.otp_status === 'disabled'"
                    @click="verify"
                >
                  Aktivieren
                </v-btn>
                <v-btn
                    :loading="loadingOtp"
                    :disabled="loadingOtp || (!otp && !this.$store.getters.userIsLoggedInAsTrustedUser)"
                    color="primary"
                    block
                    v-if="$store.getters.user.user.otp_status === 'enabled'"
                    @click="changeOtp"
                >
                  Deaktivieren
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      loading: true,
      user: null,
      active: false,
      activeLoading: false,
      showPassword: false,
      passwordRules: [
        {text: this.$t('password_rules.min_length'), isValid: false},
        {text: this.$t('password_rules.uppercase'), isValid: false},
        {text: this.$t('password_rules.lowercase'), isValid: false},
        {text: this.$t('password_rules.number'), isValid: false},
        {text: this.$t('password_rules.special'), isValid: false}
      ],
      changePasswordForm: {
        password: null,
        new_password: null,
        new_password_confirmation: null
      },
      loadingOtp: false,
      otp: null,
      secret: {}
    }
  },
  mounted() {
    this.getMe()
  },
  computed: {
    allPasswordRulesValid() {
      return this.passwordRules.every(rule => rule.isValid);
    },
    changePasswordFormValid() {
      return (
          this.changePasswordForm.password &&
          this.changePasswordForm.new_password &&
          this.changePasswordForm.new_password_confirmation &&
          this.changePasswordForm.new_password === this.changePasswordForm.new_password_confirmation &&
          this.allPasswordRulesValid
      );
    },
  },
  methods: {
    getMe() {
      this.loading = true
      this.$store.dispatch('getMe')
          .then(resp => {
            this.user = resp
            this.loading = false
            this.active = false
            this.activeLoading = false

            if (this.$store.getters.user.user.otp_status === 'disabled') {
              this.getSecret()
            }
          })
    },
    getSecret() {
      this.$store.dispatch('getOtpSecret', this.$store.getters.token)
          .then(resp => {
            this.secret = resp
          })
          .catch(err => {
            this.$helpers.showError(err)
          })
    },
    changePassword() {
      if (!this.changePasswordFormValid) {
        return
      }

      this.activeLoading = 'password'

      this.$store.dispatch('updatePassword', this.changePasswordForm)
          .then(() => {
            this.loading = false
            this.active = false
            this.activeLoading = false
            this.changePasswordForm = {
              password: null,
              new_password: null,
              new_password_confirmation: null
            }
            this.$helpers.showMessage('success', this.$t('password_changed'))
          })
          .catch(error => {
            this.$helpers.showError(error)
            this.activeLoading = false
          })
    },
    changeOtp() {
      if (!this.otp && !this.$store.getters.adminIsLoggedInAsUser) {
        this.$helpers.showMessage('error', this.$t('enter_otp'))
        return
      }

      this.loadingOtp = true

      this.$store.dispatch('disableOtp', {
        otp: this.otp
      })
          .then(() => {
            this.$store.dispatch('getMe')
                .then(() => {
                  this.loadingOtp = false
                })
            this.$helpers.showMessage('success', this.$t('success_otp_disabled'))
          })
          .catch(error => {
            this.$helpers.showError(error)
            this.loadingOtp = false
          })
    },
    verify() {
      if (!this.otp) {
        this.$helpers.showMessage('error', this.$t('enter_otp'))
        return
      }

      this.loadingOtp = true

      this.$store.dispatch('verifyOtp', {
        otp: this.otp,
        secret: this.secret.secret,
        token: this.$store.getters.token
      })
          .then(() => {
            this.$helpers.showMessage('success', this.$t('success_otp_enabled'))
            this.$store.dispatch('getMe')
                .then(() => {
                  this.loadingOtp = false
                })
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.loadingOtp = false
          })
    },
    handleActivateField(field) {
      if (this.activeLoading) {
        return;
      }

      this.active = field;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validatePassword() {
      const password = this.changePasswordForm.new_password || '';

      this.passwordRules[0].isValid = password.length >= 8; // Minimum 8 characters
      this.passwordRules[1].isValid = /[A-Z]/.test(password); // At least one uppercase letter
      this.passwordRules[2].isValid = /[a-z]/.test(password); // At least one lowercase letter
      this.passwordRules[3].isValid = /\d/.test(password);    // At least one number
      this.passwordRules[4].isValid = /[!@#$%^&*(),.?":{}|<>]/.test(password); // At least one special character
    },
    closeChangePassword() {
      this.active = false
      this.changePasswordForm = {
        password: null,
        new_password: null,
        new_password_confirmation: null
      }
    }
  }
};
</script>
