<template>
  <v-container
      fluid
      tag="section"
      class="pt-3 px-8 pb-8"
  >
    <v-row>
      <v-col cols="9" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">{{ $t('new_ticket') }}</p>
        <p class="text-subtitle-1">{{ $t('new_ticket_subtitle') }}</p>
      </v-col>
      <v-col cols="3" align="right" justify="end" class="pt-8 pl-3 pr-3 pb-8">
        <router-link :to="{ name: 'supportIndex' }">
          <v-btn color="primary" fab>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-form v-if="!loading">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  :label="$t('subject')"
                  prepend-icon="mdi-label"
                  maxlength="200"
                  counter
                  :disabled="loading"
                  v-model="ticket.subject"
                  :error="errors.subject"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  v-model="ticket.content"
                  color="primary darken-1"
                  :label="$t('message')"
                  prepend-icon="mdi-card-text"
                  outlined
                  :disabled="loading"
                  :error="errors.content"
              >
              </v-textarea>
            </v-col>
            <v-col
                cols="12"
                justify="right"
                align="right"
            >
              <v-btn
                  large
                  :loading="loading"
                  color="primary"
                  class="ma-2 white--text px-10"
                  :block="$vuetify.breakpoint.smAndDown"
                  @click="sendTicket"
              >
                {{ $t('send') }}
                <v-icon
                    right
                    dark
                >
                  mdi-send-circle
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-else class="mb-5 mt-5" :key="0">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      ticket: {},
      errors: {
        subject: false,
        category: false,
        content: false
      },
      loading: true,
      categories: [],
      categoryItem: []
    };
  },
  mounted() {
    this.getForm()
  },
  methods: {
    getForm() {
      this.loading = true
      this.$store.dispatch('getTicketsForm')
          .then(resp => {
            this.categories = resp.categories
            let items = []
            for (let index = 0; index < resp.categories.length; index++) {
              const category = resp.categories[index];
              items.push({text: category.name, value: category.id})
            }
            this.categoryItem = items
            this.loading = false
          })
    },
    sendTicket() {
      this.ticket.category = 1

      if (this.ticket.subject ? !this.ticket.subject.length : true) {
        this.errors.subject = true
        setTimeout(() => {
          this.errors.subject = false
        }, 2000);
        return
      }
      if (!this.ticket.category) {
        this.errors.category = true
        setTimeout(() => {
          this.errors.category = false
        }, 2000);
        return
      }
      if (this.ticket.content ? !this.ticket.content.length : true) {
        this.errors.content = true
        setTimeout(() => {
          this.errors.content = false
        }, 2000);
        return
      }

      this.loading = true
      this.$store.dispatch('sendTicket', this.ticket)
          .then(resp => {
            this.$router.push({name: 'showSupport', params: {id: resp.id}})
          })
    }
  }
};
</script>
