<template>
  <v-container
      fluid
      tag="section"
      class="pt-3 px-8 pb-8"
  >
    <v-row>
      <v-col cols="9" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">{{ $t('upload_new_file') }}</p>
      </v-col>
      <v-col cols="3" align="right" justify="end" class="pt-8 pl-3 pr-3 pb-8">
        <router-link :to="{ name: 'tresorIndex' }">
          <v-btn color="primary" fab>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-form v-if="!uploading">
          <v-row>
            <v-col cols="12" md="6">
              <v-file-input
                  v-model="file"
                  color="primary darken-1"
                  :label="$t('new_file')"
                  :placeholder="$t('select_file')"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :disabled="uploading"
                  :show-size="1024"
                  :error="!file && fileError"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  outlined
                  :label="$t('file_desc')"
                  prepend-icon="mdi-card-text-outline"
                  maxlength="200"
                  counter
                  :disabled="uploading"
                  v-model="desc"
                  :error="!desc && descError"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                justify="right"
                align="right"
            >
              <v-btn
                  large
                  :loading="uploading"
                  :disabled="uploading"
                  color="primary"
                  :block="$vuetify.breakpoint.smAndDown"
                  @click="uploadFile"
              >
                {{ $t('upload') }}
                <v-icon
                    right
                    dark
                >
                  mdi-cloud-upload-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-progress-linear v-else indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>

    <!-- Otp Dialog -->
    <v-dialog
        v-model="otpDialog"
        persistent
        max-width="600"
    >
      <v-card>
        <v-card-title>
          {{ $t('otp_title') }}
        </v-card-title>
        <v-card-text class="mt-5 text--primary">
          <v-text-field
              :label="$t('otp_code')"
              v-model="otp"
              :loading="otpLoading"
              :disabled="otpLoading"
              autocomplete="one-time-code"
              outlined
              hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pl-6 pb-6">
          <router-link :to="{ name: 'profileInfo' }" class="align-self-center">
            <span class="error--text"><v-icon class="mr-2" color="error">mdi-arrow-left</v-icon> {{ $t('cancel') }}</span>
          </router-link>
          <v-spacer></v-spacer>
          <v-btn fab color="primary" @click="loginOtp" :disabled="otpLoading || !otp">
            <v-icon>mdi-lock-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      desc: null,
      descError: false,
      fileError: false,
      file: null,
      uploading: false,
      otpLoading: false,
      otpDialog: false,
      otp: null
    };
  },
  mounted() {
    this.checkTresorFeature()
  },
  methods: {
    checkTresorFeature() {
      this.uploading = true

      return this.$store.dispatch('checkTresorFeature')
          .then(() => {
            this.uploading = false

            if (!this.$store.getters.tresorToken) {
              this.otpDialog = true
            }
          })
          .catch(() => {
            this.uploading = false
          })
    },
    uploadFile() {
      if (!this.file) {
        this.fileError = true
        setTimeout(() => {
          this.fileError = false
        }, 2000);
        return
      }
      if (!this.desc) {
        this.descError = true
        setTimeout(() => {
          this.descError = false
        }, 2000);
        return
      }

      this.uploading = true

      let formData = new FormData();
      formData.append('file', this.file, this.file.name);
      formData.append('desc', this.desc);

      this.$store.dispatch('uploadTresorFile', formData)
          .then(() => {
            this.$helpers.showMessage('success', this.$t('file_upload_success'))
            this.$router.push({name: 'tresorIndex'})
          })
          .catch(err => {
            this.uploading = false
            this.$helpers.showError(err)
          })
    },
    loginOtp() {
      if (!this.otp) {
        this.$helpers.showMessage('error', this.$t('enter_otp'))
        return
      }

      this.otpLoading = true
      this.$store.dispatch('tresorOtp', {
        otp: this.otp,
      })
          .then(() => {
            this.otpDialog = false
            this.getMe()
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.otpLoading = false

            if (err.response.status === 412) {
              setTimeout(() => {
                this.$router.push({
                  name: 'profilePassword',
                });
              }, 1000)
            }
          })
    },
  }
};
</script>
