import Vue from 'vue'
import VueRouter from 'vue-router';
import VueRouteMiddleware from 'vue-route-middleware';

import routes from "./routes";
import lang from "@/plugins/i18n";
import store from "../../store";
import helpers from "@/helpers/helpers";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

router.beforeEach(VueRouteMiddleware());

let allowedRoutesForTrustedUser = [
    'collections',
    'personaldocsIndex',
    'advisorIndex',
    'lastwishIndex',
    'tresor'
];

let allowedRoutesForAdmin = [
    'advisorIndex'
];

router.beforeEach((to, from, next) => {
    // Check if the route has an 'activeUntil' date and disable the route after that date
    const activeUntil = to.meta.activeUntil;

    if (activeUntil && new Date() > new Date(activeUntil)) {
        next({name: 'home'});
        return;
    }

    // Email verification logic for the 'authVerify' route
    if (to.name === 'authVerify') {
        let finalQuery = null;

        for (const key in to.query) {
            if (Object.hasOwnProperty.call(to.query, key)) {
                const value = to.query[key];
                finalQuery = (finalQuery ? (finalQuery + '&') : '') + key + '=' + value;
            }
        }

        store.dispatch('verifyEmail', finalQuery)
            .then(() => {
                helpers.showMessage('success', lang.t('success_verify'))
            })
            .catch(err => {
                helpers.showError(err)
            })
            .finally(() => {
                next({name: 'home'});
            });
    } else {
        // first check allowed dashboard routes for trusted users and admins
        let allowed = true

        if (typeof to.matched[1] !== 'undefined' && to.matched[1].name === 'dashboard') {
            let routeIndexName = to.matched[2].name ?? ''

            if (store.getters.userIsLoggedInAsTrustedUser && !allowedRoutesForTrustedUser.includes(routeIndexName)
                || (store.getters.adminIsLoggedInAsUser && store.getters.adminRole === 'alleato Admin' && !allowedRoutesForAdmin.includes(routeIndexName)))
                allowed = false
        }

        if (allowed) {
            // set meta tags
            if (to.meta && to.meta.title) {
                document.title = to.meta.title;
            } else {
                document.title = lang.t('meta_tags')['home'].title;
            }

            const metaDescription = document.querySelector('meta[name="description"]');

            if (metaDescription && to.meta.description) {
                metaDescription.setAttribute('content', to.meta.description);
            } else if (metaDescription) {
                metaDescription.setAttribute('content', lang.t('meta_tags')['home'].description);
            }

            next();
        } else {
            next({path: from.path})
        }
    }
});

export default router
