<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">

    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold pb-7">
          <span class="pr-5">{{ $t('collections_botschaften_title') }}</span>
          <span> <HelpVideo video="botschaften" title="Persönliche Botschaften"></HelpVideo></span>
        </p>
        <p class="text-subtitle-1">{{ $t('collections_botschaften_subtitle') }}</p>
      </v-col>
    </v-row>

    <!-- Head Buttons -->
    <v-row class="mt-5">
      <v-col cols="12">
        <v-tabs v-model="tab" color="primary darken-1" @change="getMedias" centered icons-and-text show-arrows grow>
          <v-tab class="flex-grow-1 pl-3 pr-3" @click="switchTab('images')">{{ $t('photos') }}
            <v-icon class="mb-2">mdi-image-multiple-outline</v-icon>
          </v-tab>
          <v-tab class="flex-grow-1 pl-3 pr-3" @click="switchTab('documents')">{{ $t('documents') }}
            <v-icon class="mb-2">mdi-file-document-multiple-outline</v-icon>
          </v-tab>
          <v-tab class="flex-grow-1 pl-3 pr-3" @click="switchTab('videos')">{{ $t('videos') }}
            <v-icon class="mb-2">mdi-movie-open-outline</v-icon>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <!-- Medias -->
    <transition name="slide" mode="out-in">
      <v-row v-if="medias && !loading" class="mt-10" :key="1">
        <!-- Images -->
        <v-col cols="12" v-if="isType('images')">
          <div v-if="medias.length ? medias[0].mime_type.startsWith('image') : false">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th></th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('description') }}</th>
                  <th class="text-left">{{ $t('size') }}</th>
                  <th class="text-left">{{ $t('date') }}</th>
                  <th class="text-right">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(media, i) in medias" :key="media.id">
                  <td>{{ i + 1 }}</td>
                  <td><img v-if="media.thumbnail" :alt="media.name" v-bind:src="media.thumbnail" class="py-2"/></td>
                  <td>{{ media.name }}</td>
                  <td>{{ media.desc }}</td>
                  <td>{{ getSize(media.size) }}</td>
                  <td>{{ $helpers.formatDate(media.created_at, 'DD.MM.YYYY') }}</td>
                  <td class="text-right">
                    <v-btn small text fab color="error" @click="deleteMedia(media.id)" v-if="showForUser">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <a v-auth-href :href="media.url">
                      <v-btn small text fab color="primary">
                        <v-icon>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>

        <!-- Videos -->
        <v-col cols="12" v-if="isType('videos')">
          <div v-if="medias.length ? medias[0].mime_type.startsWith('video') : false">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th></th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('description') }}</th>
                  <th class="text-left">{{ $t('size') }}</th>
                  <th class="text-left">{{ $t('date') }}</th>
                  <th class="text-right">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(media, i) in medias" :key="media.id">
                  <td>{{ i + 1 }}</td>
                  <td><img v-if="media.thumbnail" :alt="media.name" v-bind:src="media.thumbnail" class="py-2"/></td>
                  <td>{{ media.name }}</td>
                  <td>{{ media.desc }}</td>
                  <td>{{ getSize(media.size) }}</td>
                  <td>{{ $helpers.formatDate(media.created_at, 'DD.MM.YYYY') }}</td>
                  <td class="text-right">
                    <v-btn small text fab color="error" @click="deleteMedia(media.id)" v-if="showForUser">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <a v-auth-href :href="media.url">
                      <v-btn small text fab color="primary">
                        <v-icon>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>

        <!-- Documents -->
        <v-col cols="12" v-if="isType('documents')">
          <div v-if="medias.length ? medias[0].mime_type.startsWith('application') : false">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('description') }}</th>
                  <th class="text-left">{{ $t('size') }}</th>
                  <th class="text-left">{{ $t('date') }}</th>
                  <th class="text-right">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(media, i) in medias" :key="media.id">
                  <td>{{ i + 1 }}</td>
                  <td>{{ media.name }}</td>
                  <td>{{ media.desc }}</td>
                  <td>{{ getSize(media.size) }}</td>
                  <td>{{ $helpers.formatDate(media.created_at, 'DD.MM.YYYY') }}</td>
                  <td class="text-right">
                    <v-btn small text fab color="error" @click="deleteMedia(media.id)" v-if="showForUser">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <a v-auth-href :href="media.url">
                      <v-btn small text fab color="primary">
                        <v-icon>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>

        <!-- Add New File Button -->
        <transition name="slide" mode="out-in" v-if="showForUser">
          <v-col justify="center" align="center" cols="12" class="mt-10 mb-16">
            <v-btn fab x-large color="primary" :to="{name: $route.params.type === 'documents' ? 'newDocument' : 'newMedia', params: { type: $route.params.type }}">
              <v-icon color="white">mdi-cloud-upload-outline</v-icon>
            </v-btn>
            <p class="text-subtitle-1 mt-5">{{ $t('upload_new_file') }}</p>
          </v-col>
        </transition>
      </v-row>
      <v-row v-else-if="loading" class="mb-5 mt-10" :key="0">
        <v-col cols="12">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </v-row>
    </transition>

    <!-- Delete Media Confirm Dialog -->
    <v-dialog
        v-model="deleteConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title>
          {{ $t('are_you_sure') }}
        </v-card-title>
        <v-card-text class="text--primary">
          {{ $t('delete_file_alert') }}
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pl-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="deleteMedia(selectedMedia)"
              :block="$vuetify.breakpoint.smAndDown"
          >
            {{ $t('yes') }}
          </v-btn>
          <v-btn
              color="primary"
              @click="deleteConfirmDialog = false; selectedMedia = null;"
              :block="$vuetify.breakpoint.smAndDown"
              :class="{
                'ml-0 mt-2': $vuetify.breakpoint.smAndDown
              }"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NoSubscriptionDialog/>
  </v-container>
</template>

<script>

import NoSubscriptionDialog from "@/dialogs/NoSubscriptionDialog.vue";
import NoFilesUploaded from "@/widgets/NoFilesUploaded.vue";
import HelpVideo from "@/widgets/HelpVideo.vue";

export default {
  components: {
    NoFilesUploaded,
    NoSubscriptionDialog,
    HelpVideo
  },
  data() {
    return {
      loading: true,
      medias: null,
      tab: 0,
      fabLoader: false,
      deleteConfirmDialog: false,
      selectedMedia: null,
      deleteMediaLoading: false,
      showForUser: !this.$store.getters.userIsLoggedInAsTrustedUser
    }
  },
  watch: {
    $route(to, from) {
      this.getMedias()

      if (from.params.collection !== to.params.collection) {
        this.loading = true
        this.medias = null
        this.type = 'images'
        this.fabLoader = false
        this.deleteConfirmDialog = false
        this.selectedMedia = null
        this.deleteMediaLoading = false
      }
    },
    '$route.params.collection'() {
      this.$router.go({name: 'mediaIndex', params: {collection: this.$route.params.collection, type: 'images'}})
    }
  },
  mounted() {
    if (!['images', 'videos', 'documents'].includes(this.$route.params.type)) {
      this.$router.push({name: 'dashboard/profile/info'})
      return
    }

    this.type = this.$route.params.type

    switch (this.$route.params.type) {
      case 'images':
        this.tab = 0
        break;
      case 'documents':
        this.tab = 1
        break;
      case 'videos':
        this.tab = 2
        break;
    }
    this.getMedias()
  },
  methods: {
    isType(type) {
      return this.$route.params.type === type;
    },
    switchTab(type) {
      if (!this.isType(type)) {
        this.medias = null;
        this.$router.push({params: {type}});
      }
    },
    getMedias() {
      this.loading = true
      let type = null

      if (this.$route.params.type !== 'all') {
        type = this.$route.params.type
      }

      if (type === 'documents') {
        this.$store.dispatch('getDocuments', {
          collection: this.$route.params.collection,
          category: null,
        })
            .then(resp => {
              this.medias = resp
              this.loading = false
            })
            .catch(err => {
              this.loading = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      } else {
        this.$store.dispatch('getMedias', {
          collection: this.$route.params.collection,
          type: type,
          category: null
        })
            .then(resp => {
              this.medias = resp
              this.loading = false
            })
            .catch(err => {
              this.loading = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      }
    },
    deleteMedia(id) {
      if (!this.selectedMedia || !this.deleteConfirmDialog) {
        this.selectedMedia = id
        this.deleteConfirmDialog = true
      } else {
        this.deleteMediaLoading = id
        this.fabLoader = id
        this.deleteConfirmDialog = false
        this.$store.dispatch('deleteMedia', id)
            .then(() => {
              this.fabLoader = false
              this.deleteMediaLoading = false
              this.selectedMedia = null
              this.getMedias()
            })
            .catch(err => {
              this.deleteMediaLoading = false
              this.fabLoader = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      }
    },
    getSize(size) {
      let s = Math.floor(size / 1024)
      let format = 'KB'
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'MB'
      }
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'GB'
      }
      return `${s} ${format}`
    },
  }
}
</script>
