<template>
  <section class="d-flex flex-column">
    <Header @open-login-modal="showLogin = true" @open-register-modal="showRegister = true"/>
    <v-container>
      <TextWithImage
          :cols="[8,4]"
          image-col="2"
          :title="this.$t('about_us')"
          :text="this.$store.getters.settings.site_about || ''"
          image="/assets/img/banner.png"
          image-width="500"
          button=""
      >
      </TextWithImage>
    </v-container>
    <Footer
        :showLogin="showLogin"
        :showRegister="showRegister"
        @close-login-modal="showLogin = false"
        @close-register-modal="showRegister = false"
    />
  </section>
</template>

<script>
import TextWithImage from "../../widgets/TextWithImage.vue";
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  components: {
    TextWithImage,
    Header,
    Footer,
  },
  data() {
    return {
      showLogin: false,
      showRegister: false
    }
  },
}
</script>
