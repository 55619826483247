<template>
  <section class="d-flex flex-column">
    <v-row class="ma-0 pb-0">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="8" justify="center" align-self="center" align="center">
        <a @click="redirectToHome">
          <v-img src="/assets/img/logo.svg" alt="Geras24" :width="300" contain></v-img>
        </a>
      </v-col>
      <v-col cols="12" md="4" class="auth-bg pa-6">
        <v-container class="pa-4">
          <v-row class="mb-3">
            <h1 class="text-h5">{{ $t('reset_password') }}</h1>
          </v-row>
          <v-row>
            <v-text-field
                :label="$t('new_password')"
                :type="showPassword ? 'text' : 'password'"
                :disabled="loading"
                outlined
                v-model="form.password"
                :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="togglePasswordVisibility"
                @input="validatePassword"
            ></v-text-field>
          </v-row>
          <v-row v-if="form.password && !this.allPasswordRulesValid" class="mt-0 mb-4 password-rules">
            <v-col v-for="rule in passwordRules" :key="rule.text" cols="12" class="pa-0">
              <v-icon :color="rule.isValid ? 'success' : 'error'" size="20">
                {{ rule.isValid ? 'mdi-check' : 'mdi-close' }}
              </v-icon>
              <span :class="rule.isValid ? 'text-success' : 'text-error'">
                  {{ rule.text }}
                </span>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-text-field
                :label="$t('confirm_new_password')"
                :type="showPassword ? 'text' : 'password'"
                :disabled="loading"
                outlined
                v-model="form.password_confirmation"
                :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                @click:append="togglePasswordVisibility"
            ></v-text-field>
          </v-row>
          <v-row class="mt-2" justify="center">
            <v-btn
                :loading="loading"
                :disabled="loading"
                color="white"
                block
                large
                @click="setNewPassword"
            >
              {{ $t('reset_password') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      showPassword: false,
      passwordRules: [
        {text: this.$t('password_rules.min_length'), isValid: false},
        {text: this.$t('password_rules.uppercase'), isValid: false},
        {text: this.$t('password_rules.lowercase'), isValid: false},
        {text: this.$t('password_rules.number'), isValid: false},
        {text: this.$t('password_rules.special'), isValid: false}
      ],
      form: {
        password: null,
        password_confirmation: null,
        token: null
      },
    }
  },
  computed: {
    allPasswordRulesValid() {
      return this.passwordRules.every(rule => rule.isValid);
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    setNewPassword() {
      if (!this.formValid()) {
        return;
      }

      this.loading = true
      this.form.token = this.$route.params.token

      this.$store.dispatch('setNewPassword', this.form)
          .then(() => {
            this.$helpers.showMessage('success', this.$t('password_changed'))

            setTimeout(() => {
              this.redirectToHome()
            }, 2000)
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.loading = false
          })
    },
    formValid() {
      if (!this.form.password) {
        this.$helpers.showMessage('error', this.$t('enter_password'))
        return false;
      }

      if (!this.allPasswordRulesValid) {
        this.$helpers.showMessage('error', this.$t('password_not_valid'))
        return false;
      }

      if (!this.form.password_confirmation) {
        this.$helpers.showMessage('error', this.$t('enter_password_confirm'))
        return false;
      }

      if (this.form.password !== this.form.password_confirmation) {
        this.$helpers.showMessage('error', this.$t('password_confirm_error'))
        return false;
      }

      return true;
    },
    validatePassword() {
      const password = this.form.password || '';

      this.passwordRules[0].isValid = password.length >= 8; // Minimum 8 characters
      this.passwordRules[1].isValid = /[A-Z]/.test(password); // At least one uppercase letter
      this.passwordRules[2].isValid = /[a-z]/.test(password); // At least one lowercase letter
      this.passwordRules[3].isValid = /\d/.test(password);    // At least one number
      this.passwordRules[4].isValid = /[!@#$%^&*(),.?":{}|<>]/.test(password); // At least one special character
    },
    redirectToHome() {
      window.location.href = '/'; // Do not use router here!
    }
  }
}
</script>
