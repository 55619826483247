<template>
  <v-container
      fluid
      tag="section"
      class="pt-3 px-8 pb-8"
  >
    <!-- Main Section -->
    <v-row>
      <v-col cols="12" class="pt-3 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">{{ $t('entrusted_to_me_title') }}</p>
        <div class="subtitle-1 font-weight-light main-grey">
          {{ $t('entrusted_to_me_desc') }}
        </div>
        <div class="d-flex mt-4">
          <v-text-field
              v-model="currentUserId"
              readonly
              class="flex-grow-1"
              append-icon="mdi-content-copy"
              @click:append="copyToClipboard"
              outlined
              hide-details
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <!-- Data Tabs -->
        <v-row class="mt-1">
          <v-col cols="12">
            <v-row v-if="!usersLoading">
              <v-col cols="12">
                <div v-if="users ? users.length : false">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">#</th>
                        <th class="text-left">{{ $t('name') }}</th>
                        <th class="text-left">{{ $t('email') }}</th>
                        <th class="text-right">{{ $t('actions') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(user, i) in users" :key="user.id">
                        <td>{{ i + 1 }}</td>
                        <td>{{ user.first_name }} {{ user.last_name }}</td>
                        <td>{{ user.email }}</td>
                        <td class="text-right">
                          <v-btn small text fab color="primary" @click="changeDashboard(user.id)">
                            <v-icon>mdi-view-dashboard-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <v-alert
                    v-else
                    color="primary"
                    class="white--text"
                    elevation="5"
                >
                  <span class="text-center text-sm-left">{{ $t('no_entrusted_users') }}</span>
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-else class="mb-5 mt-5" :key="0">
              <v-col cols="12">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      currentUserId: this.$store.getters.user.user.id || '',
      usersLoading: true,
      users: null,
    };
  },
  mounted() {
    this.getEntrustedUsers()
  },
  methods: {
    getEntrustedUsers() {
      this.usersLoading = true
      this.$store.dispatch('getEntrustedUsers')
          .then(resp => {
            this.users = resp
            this.usersLoading = false
          })
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.currentUserId);
        this.$helpers.showMessage('success', this.$t('trusted_user_id_copied'));
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    changeDashboard(user_id) {
      this.$store.commit('login_user_as_trusted_user', this.currentUserId)

      this.$store.dispatch('loginAsUser', {userID: user_id})
          .then(() => {
            window.location = this.$store.getters.frontUrl + '/dashboard/botschaften/medias/list/images'
          })
          .catch(err => {
            this.loading = false
            this.$helpers.showError(err)
          })
    },
  },
}
</script>
