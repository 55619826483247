<template>
  <v-app
      v-if="!$route.meta.authOnly"
      :style="{ marginTop: $route.name === 'resetPassword' ? '0' : '90px' }">
    <router-view/>
    <vue-progress-bar></vue-progress-bar>
  </v-app>
  <section v-else>
    <transition name="slide" mode="out-in">
      <router-view/>
    </transition>
  </section>
</template>

<script>
export default {
  data() {
    return {
      langs: ['en', 'de', 'jp', 'tr'],
      loading: false
    }
  },
  mounted() {
    this.$store.dispatch('getSettings')

    this.$store.dispatch('getMe')
        .catch(() => {
          if (this.$store.getters.isLoggedIn) {
            this.$helpers.showMessage('error', this.$t('token_expired'))
            this.$store.commit('logout')
            localStorage.removeItem('token')
            delete this.axios.defaults.headers.common['Authorization']
            this.$router.go({name: 'home'})
          }
        })
  },
}
</script>
