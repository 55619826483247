<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialogVisible"
        max-width="450"
        persistent
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('need_payment') }}
        </v-card-title>
        <v-card-text>
          {{ $t('need_payment_desc') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error darken-1"
              text
              @click="handleLater"
          >
            {{ $t('later') }}
          </v-btn>
          <v-btn
              color="primary darken-1"
              text
              @click="handleBuyNow"
          >
            {{ $t('buy_now') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  computed: {
    userSubscription() {
      return this.$store.getters.user.user.subscription;
    },
    isLoggedInAsAnotherUser() {
      return this.$store.getters.userIsLoggedInAsTrustedUser || this.$store.getters.adminIsLoggedInAsUser;
    },
    shouldShowDialog() {
      if (this.isLoggedInAsAnotherUser) {
        return false;
      }

      return this.userSubscription === null;
    }
  },
  watch: {
    userSubscription(newValue) {
      // Open the dialog if subscription is null
      this.dialogVisible = newValue === null;
    }
  },
  methods: {
    handleLater() {
      this.$router.push({name: 'profileInfo'});
    },
    handleBuyNow() {
      this.$router.push({name: 'profilePlans'});
    }
  },
  mounted() {
    this.dialogVisible = this.shouldShowDialog;
  }
}
</script>
