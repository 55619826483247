<template>
    <transition name="hslide" mode="out-in">
        <router-view />
    </transition>
</template>

<script>
export default {

}
</script>
