<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">

    <!-- Head Buttons -->
    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <div class="text-h5 main-font-bold pb-10">
          <span class="pr-5">{{ $t('checklist_title') }}</span>
          <HelpVideo video="checkliste" title="Die Checkliste"></HelpVideo>
        </div>
        <div class="text-subtitle-1">{{ $t('checklist_subtitle') }}</div>
      </v-col>
      <v-col align="right" justify="end">
        <v-btn
            large
            color="primary"
            @click="$refs.html2Pdf.generatePdf()"
            :block="$vuetify.breakpoint.smAndDown"
        >
          <v-icon color="white">mdi-file-pdf</v-icon>
          Checkliste herunterladen
        </v-btn>
      </v-col>
    </v-row>

    <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        filename="checklist"
        :paginate-elements-by-height="1100"
        :pdf-quality="2"
        pdf-format="a3"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :manual-pagination="false"
        ref="html2Pdf"
    >
      <!-- Medias -->
      <v-row class="mt-10 mr-3" slot="pdf-content">
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left font-weight-bold">{{ $t('checklist_checkliste') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_meldepflichtig') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_frist_nach_todesfall') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_besonderheiten') }}</th>
                <th class="text-left font-weight-bold">{{ $t('checklist_notizen') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in checklists" :key="i">
                <td class="py-5 font-weight-bold" v-html="item.content.name"></td>
                <td class="py-5" v-html="item.content.meldepflichtig || ''"></td>
                <td class="py-5" v-html="item.content.frist_nach_todesfall || ''"></td>
                <td class="py-5" v-html="item.content.besonderheiten || ''"></td>
                <td class="py-5" v-if="item.note">
                  <div v-html="nl2br(item.note)"></div>
                  <v-btn class="mt-3" small color="primary" @click="addEditNote(item)">
                    <v-icon>mdi-pencil-outline</v-icon>
                  </v-btn>
                </td>
                <td class="py-5" v-else>
                  <v-btn small color="primary" @click="addEditNote(item)">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </vue-html2pdf>

    <!-- Add Note Dialog -->
    <v-dialog
        v-model="addNoteDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          <span v-if="activeChecklistNote && activeChecklistNote.note">{{ $t('update_checklist_note') }}</span>
          <span v-else>{{ $t('add_note_to_checklist') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addNoteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5 text--primary">
          <v-textarea
              outlined
              :label="$t('note')"
              maxlength="200"
              counter
              :disabled="addNoteDialogLoading"
              :loading="addNoteDialogLoading"
              v-model="checklistNote.note"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pl-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              :block="$vuetify.breakpoint.smAndDown"
              :disabled="addNoteDialogLoading"
              :loading="addNoteDialogLoading"
              @click="setNote()"
          >
            <span v-if="activeChecklistNote && activeChecklistNote.note">{{ $t('update') }}</span>
            <span v-else>{{ $t('add') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NoSubscriptionDialog/>
  </v-container>
</template>

<script>

import NoSubscriptionDialog from "@/dialogs/NoSubscriptionDialog.vue";
import HelpVideo from "@/widgets/HelpVideo.vue";

export default {
  data() {
    return {
      checklists: null,
      loading: true,
      addNoteDialog: false,
      addNoteDialogLoading: false,
      activeChecklistNote: null,
      checklistNote: {
        checklist_id: null,
        note: null,
      },

      htmlToPdfOptions: {
        margin: 0,

        filename: `checklist.pdf`,

        image: {
          type: 'jpeg',
          quality: 0.98
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true
        },

      }
    }
  },
  components: {
    NoSubscriptionDialog,
    HelpVideo
  },
  mounted() {
    this.getChecklists()
  },
  methods: {
    getChecklists() {
      this.$store.dispatch('getChecklists')
          .then(resp => {
            this.checklists = resp
            this.loading = false
          })
          .catch(err => {
            this.$helpers.showError(err)
          })
    },
    addEditNote(data) {
      this.activeChecklistNote = data;
      this.checklistNote = {
        checklist_id: data.id,
        note: data.note
      }

      this.addNoteDialog = true
    },
    setNote() {
      this.addNoteDialogLoading = true
      this.$store.dispatch('setNote', this.checklistNote)
          .then(() => {
            this.checklistNote = {
              checklist_id: null,
              note: null
            }
            this.addNoteDialogLoading = false
            this.addNoteDialog = false
            this.loading = true
            this.getChecklists()
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.addNoteDialogLoading = false
          })
    },
    nl2br(str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return '';
      }

      let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
  }
}
</script>
